<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Leave</h2>
        <v-row>
            <v-col cols="12" >
                <v-card outlined elevation="2" @click="leaveCreate" style="padding: 10px;">
                    <p style="color: #002060; font-size:18px;margin-bottom: 0px;">1. Leave Request</p>
                </v-card>
            </v-col>
            <v-col cols="12" >
                <v-card outlined elevation="2" style="padding: 10px;" @click="leaveStatus">
                    <p style="color: #002060; font-size:18px;margin-bottom: 0px;">2. View Status</p>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    name: "Leave",
    data: () => ({
        user_p : JSON.parse(localStorage.getItem('user'))
    }),
    methods:{
        leaveCreate() {
            this.$router.push('/leave/request')
        },
        leaveStatus() {
            this.$router.push('/leave/status')
        }
    }
}
</script>